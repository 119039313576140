import React from "react"
import { Link } from "gatsby"
import useMinimalConfig from "../../hooks/use-minimal-config"
import useSiteMetadata from "../../hooks/use-site-metadata"
import replaceSlashes from "../../../utils/replace-slashes"
import { FooterType } from "../../types"

type LayoutProps = {
  footer: FooterType
  [key: string]: any
}

const FooterDefault = ({ footer, ...props }: LayoutProps) => {
  const { footerNavigation: nav, basePath } = useMinimalConfig()
  const { siteTitle } = useSiteMetadata()

  return (
    <footer className={`bg-footerBackground text-footerColor ${footer?.advanced?.class || ``}`}>
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0 py-12 overflow-hidden">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {nav.map((item:any, itemIndex:any) => (
            <div key={`footer-${itemIndex}`} className="px-5 py-2">
              <Link key={item.slug} className="text-footerColor" to={replaceSlashes(`/${basePath}/${item.slug}`)}>
                {item.title}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-footerColor">
          &copy; { new Date().getFullYear() } {siteTitle} All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default FooterDefault
